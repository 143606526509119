import React from 'react'
import T from 'prop-types'
import Helmet from 'react-helmet'
import { get } from 'lodash'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'
// eslint-disable-next-line no-restricted-imports
import LOGO from 'assets/images/icons/icon-192x192.png'

export default function SEO({
  description,
  title = 'Oppskrifter og kurs designet for mobilen',
  image,
}) {
  const { site, defaultImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
        defaultImage: file(relativePath: { eq: "default-some.jpg" }) {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              src
            }
          }
        }
      }
    `,
  )

  let img = image || defaultImage || null

  if (get(img, 'childImageSharp')) {
    img = `${site.siteMetadata.siteUrl}${get(img, 'childImageSharp.fixed.src')}`
  }

  const { pathname } = useLocation()
  const url = `${site.siteMetadata.siteUrl}${pathname}`

  const schemaImage = img ? { image: { '@type': 'ImageObject', url: img } } : {}

  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'DiscussionForumPosting',
      url,
      name: site.siteMetadata.title,
      headline: title,
      description,
      ...schemaImage,
      publisher: {
        '@type': 'Organization',
        url: site.siteMetadata.siteUrl,
        logo: LOGO,
        name: site.siteMetadata.title,
      },
    },
  ]

  const metaDescription = description || site.siteMetadata.description || ''
  const origin =
    typeof window !== 'undefined'
      ? window.location.origin
      : 'https://foodsteps.no'

  return (
    <Helmet
      defaultTitle={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      htmlAttributes={{ lang: 'no' }}
    >
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />

      <meta name="description" content={metaDescription} />
      <meta property="og:description" content={metaDescription} />
      <meta name="twitter:description" content={metaDescription} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="fb:app_id" content="484677348913468" />

      {img && <meta name="image" content={img} />}
      {img && <meta property="og:image" content={img} />}
      {img && <meta property="twitter:image" content={img} />}

      <meta name="twitter:card" content="summary" />

      <meta name="appleid-signin-client-id" content="no.foodsteps.web" />
      <meta
        name="appleid-signin-redirect-uri"
        content={`${origin}/auth/appleid`}
      />
      <meta name="appleid-signin-scope" content="name email" />
      <meta name="appleid-signin-use-popup" content="true" />

      <meta
        name="google-site-verification"
        content="SlXpBjCaEyCBQbRukyymrFMIkUmu18bIe4E7UofLzuc"
      />

      {/* Sign in with Apple */}
      <script
        defer
        type="text/javascript"
        src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
      />

      <script async type="text/javascript">{`
        window.fbAsyncInit = function() {
          FB.init({
            appId: '484677348913468',
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v7.0',
          })
        }
      `}</script>
      <script async defer src="https://connect.facebook.net/en_US/sdk.js" />

      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  )
}

SEO.propTypes = {
  description: T.string,
  title: T.string.isRequired,
  image: T.oneOfType([
    T.string,
    T.shape({
      childImageSharp: T.shape({
        fixed: T.shape({
          src: T.string.isRequired,
        }).isRequired,
      }).isRequired,
    }),
  ]),
}
